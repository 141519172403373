// Load dependencies
import "trix";
// @ts-ignore
import Rails from "@rails/ujs";
Rails.start();
import "@rails/actiontext";
import "bootstrap";
import "htmx.org";

// Load the main style
import "./style.sass";

import "./items";
import "./itemsets";
import "./monsters";
import "./spells";
import "./dungeons";