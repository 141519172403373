import "./monsters.sass";

function trimDigits(digits: string): string {
    return digits
        .replace(/\.0+$/, '')
        .replace(/\.(\d+?)0+$/, '.$1'); // Needs a nongreedy marker...
}

[...document.getElementsByClassName('monster-rank-tab')].forEach((_tab: Element) => {
    const tab = _tab as HTMLButtonElement;
    const {monsterId, monsterRank} = tab.dataset;
    if (!monsterId || !monsterRank) return;

    const lootEls = [...document.getElementsByClassName(`loot_percent_${monsterId}`)];
    // keep element => rate for the current rank
    const loots: [HTMLSpanElement, number][] = lootEls.map(_lootEl => {
        const lootEl = _lootEl as HTMLSpanElement;
        const {ratePerRank} = lootEl.dataset;
        if (!ratePerRank) return [lootEl, 100]; // shouldn't happen (missing data)

        // rate for current rank
        const rateForRank = ratePerRank.split(',').find(rpr => 0 === rpr.indexOf(`${monsterRank}:`));
        if (!rateForRank) return [lootEl, 100]; // shouldn't happen (missing rank in data)

        const percent = rateForRank.split(':')[1] || '100';
        return [lootEl, +percent];
    })

    tab.addEventListener('click', () => {
       loots.forEach(([lootEl, percent]) => {
            lootEl.innerText = `${trimDigits(percent.toPrecision(4 ))}%`;
       })
    });
})