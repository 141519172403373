import "./items.sass";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {useState} from "react";

const EFFECT_ID_TO_KEY: { [id: number]: string } = {
    // 84: "AP_STEAL",
    91: "LIFE_STEAL_WATER",
    92: "LIFE_STEAL_EARTH",
    93: "LIFE_STEAL_AIR",
    94: "LIFE_STEAL_FIRE",
    95: "LIFE_STEAL_NEUTRAL",
    96: "DAMAGE_WATER",
    97: "DAMAGE_EARTH",
    98: "DAMAGE_AIR",
    99: "DAMAGE_FIRE",
    100: "DAMAGE_NEUTRAL",
    101: "AP_LOST",
    108: "LIFE_RESTORE",
    110: "LIFE",
    111: "AP",
    112: "DAMAGE",
    115: "CRIT",
    116: "RANGE_NEG",
    117: "RANGE",
    118: "STRENGTH",
    119: "AGILITY",
    122: "CRIT_FAIL",
    123: "CHANCE",
    124: "WISDOM",
    125: "VITALITY",
    126: "INTELLIGENCE",
    127: "MP_LOST",
    128: "MP",
    130: "KAMAS_STEAL",
    138: "DAMAGE_PER",
    139: "ENERGY_RESTORE",
    152: "CHANCE_NEG",
    153: "VITALITY_NEG",
    154: "AGILITY_NEG",
    155: "INTELLIGENCE_NEG",
    156: "WISDOM_NEG",
    157: "STRENGTH_NEG",
    158: "WEIGHT",
    159: "WEIGHT_NEG",
    171: "CRIT_NEG",
    174: "INI",
    175: "INI_NEG",
    176: "PROSPECTION",
    177: "PROSPECTION_NEG",
    178: "HEAL",
    182: "SUMMON",
    194: "KAMAS",
    210: "EARTH_RES_PER",
    211: "WATER_RES_PER",
    212: "AIR_RES_PER",
    213: "FIRE_RES_PER",
    214: "NEUTRAL_RES_PER",
    215: "EARTH_WEAK_PER",
    216: "WATER_WEAK_PER",
    217: "AIR_WEAK_PER",
    218: "FIRE_WEAK_PER",
    219: "NEUTRAL_WEAK_PER",
    220: "REFLECT_DAMAGE",
    225: "TRAP_DAMAGE",
    226: "TRAP_DAMAGE_PER",
    240: "EARTH_RES",
    241: "WATER_RES",
    242: "AIR_RES",
    243: "FIRE_RES",
    244: "NEUTRAL_RES",
    250: "EARTH_RES_PER_PVP",
    251: "WATER_RES_PER_PVP",
    252: "AIR_RES_PER_PVP",
    253: "FIRE_RES_PER_PVP",
    254: "NEUTRAL_RES_PER_PVP",
    260: "EARTH_RES_PVP",
    261: "WATER_RES_PVP",
    262: "AIR_RES_PVP",
    263: "FIRE_RES_PVP",
    264: "NEUTRAL_RES_PVP",
    605: "XP",
    606: "ADD_WISDOM",
    607: "ADD_STRENGTH",
    608: "ADD_CHANCE",
    609: "ADD_AGILITY",
    610: "ADD_VITALITY",
    611: "ADD_INTELLIGENCE",
    613: "SPELL_POINTS",
    // 623: "INVOKE_SOULSTONE",
    701: "POWER",
    702: "DURABILITY",
};
declare const getEffectName: (id: number) => string;

const SEARCH_OPS = {
    eq: "=",
    gt: ">",
    gteq: ">=",
    lt: "<",
    lteq: "<="
    // TODO more
}

function SearchStat({id, unuse, data, setData}: {
    id: number,
    unuse: () => void,
    data: StatData,
    setData: (data: StatData) => void
}) {
    const name = (EFFECT_ID_TO_KEY[id] || '').toLowerCase();
    const setFrom = (from: number) => setData({...data, from: from});
    const setOp = (op: string) => setData({...data, op: op})
    return <div>
        {getEffectName(id)}
        <select onChange={e => setOp(e.target.value)} value={data.op}>
            {Object.entries(SEARCH_OPS).map(([key, symbol]) =>
                <option key={`effect-${id}-op-${key}`} value={key}>{symbol}</option>
            )}
        </select>
        <input name={`q[effect_${name}_from_${data.op}]`} value={data.from} onChange={(e) => setFrom(+e.target.value)}/>
        <a onClick={() => unuse()}>Supprimer</a>
    </div>;
}

type StatData = {
    from: number;
    to: number;
    op: string; // TODO should it be to/from? how to do between?
}

function SearchSelect({initial}: { initial: { [key: number]: StatData | null } }) {
    const [usedStats, setUsedStats] = useState(initial);

    const unuseStat = (id: number) => {
        setUsedStats({...usedStats, [id]: null});
    }

    const useStat = (id: number) => {
        if (usedStats[id]) return; // Already hidden
        setUsedStats({...usedStats, [id]: {from: 0, to: 0, op: "gt"}});
    };

    const setData = (id: number, data: StatData) => {
        setUsedStats({...usedStats, [id]: data});
    }

    return <div id="search-form-stats-select">
        <select onChange={e => useStat(+e.target.value)}>
            <option></option>
            {Object.entries(EFFECT_ID_TO_KEY).map(([id, _]) =>
                usedStats[+id] ? null :
                    <option key={`stat-select-${id}`} value={id}>{getEffectName(+id)}</option>
            )}
        </select>
        <div id="search-form-stats-used">
            {Object.entries(usedStats).map(([id, data]) =>
                data ? <SearchStat id={+id} key={`stat-used-${id}`} data={data}
                                   unuse={() => unuseStat(+id)}
                                   setData={(data) => setData(+id, data)}
                /> : null
            )}
        </div>
    </div>;
}

function findEffect(name: string): number | void {
    for (let id in EFFECT_ID_TO_KEY) {
        if (EFFECT_ID_TO_KEY[id]!.toLowerCase() == name) return +id;
    }
}
function readInitial(): {[key: number]: StatData} {
    let EFFECT_PREFIX = 'q[effect_';
    const o: {[key: number]: StatData} = {};
    const searchParams = new URLSearchParams(window.location.search)
    for (const [k, v] of new URLSearchParams(window.location.search)) {
        if (!k.startsWith(EFFECT_PREFIX) || !k.endsWith(']')) continue;
        const key = k.substring(EFFECT_PREFIX.length, k.length-1);
        const key_parts = key.split('_');
        const op = key_parts.pop();
        const from_or_to = key_parts.pop();
        const effect_name = key_parts.join('_');
        const effect = findEffect(effect_name);
        if (!effect) continue;
        if (!o[effect]) o[effect] = {op: '<', from: 0, to: 0};
        if (from_or_to === 'from')
            o[effect]!.from = +v;
        else
            o[effect]!.to = +v;
        o[effect]!.op = op || '<';
    }
    return o;
}

void function doSearch() {
    const searchForm = document.getElementById("search-form");
    if (!searchForm) return;
    const initial = readInitial();
    ReactDOM.render(
        <SearchSelect initial={initial}/>,
        searchForm
    )
}();
//
// type ApiItem = {
//     id: number;
//     name: string;
//     description: string;
//     url: string;
// };
// const searchInput = document.querySelector('#item_search #q_name_cont') as HTMLInputElement;
// const autocompleteList = document.getElementById('autocomplete_results');
// if (searchInput && autocompleteList) {
//     const route = '/items.json?q[name_cont]='; // TODO generate url so it includes the locale(?)
//     console.log('hoho')
//     searchInput.addEventListener('keyup', () => {
//         const url = route + encodeURIComponent(searchInput.value);
//         autocompleteList.innerHTML = '';
//         console.log(url);
//         fetch(url)
//             .then(resp => resp.json())
//             .then(data => {
//                 // TODO react or something
//                 const ul = document.createElement('ul');
//                 const found = (data as ApiItem[]).slice(0, 55);
//                 found.forEach(el => {
//                     const li = document.createElement('li');
//                     const a = document.createElement('a');
//                     a.href = el.url.replace(/\.json$/, '');
//                     a.innerHTML = el.name;
//                     li.appendChild(a);
//                     ul.appendChild(li);
//                 })
//                 autocompleteList.appendChild(ul);
//             })
//     });
// }